exports.components = {
  "component---shared-templates-article-article-page-tsx": () => import("./../../../../Shared/templates/Article/ArticlePage.tsx" /* webpackChunkName: "component---shared-templates-article-article-page-tsx" */),
  "component---shared-templates-case-study-case-study-detail-tsx": () => import("./../../../../Shared/templates/CaseStudy/CaseStudyDetail.tsx" /* webpackChunkName: "component---shared-templates-case-study-case-study-detail-tsx" */),
  "component---shared-templates-category-category-page-tsx": () => import("./../../../../Shared/templates/Category/CategoryPage.tsx" /* webpackChunkName: "component---shared-templates-category-category-page-tsx" */),
  "component---shared-templates-change-log-change-log-page-tsx": () => import("./../../../../Shared/templates/ChangeLog/ChangeLogPage.tsx" /* webpackChunkName: "component---shared-templates-change-log-change-log-page-tsx" */),
  "component---shared-templates-easy-software-page-tsx": () => import("./../../../../Shared/templates/easySoftwarePage.tsx" /* webpackChunkName: "component---shared-templates-easy-software-page-tsx" */),
  "component---shared-templates-faq-faq-detail-tsx": () => import("./../../../../Shared/templates/Faq/FaqDetail.tsx" /* webpackChunkName: "component---shared-templates-faq-faq-detail-tsx" */),
  "component---shared-templates-function-function-detail-tsx": () => import("./../../../../Shared/templates/Function/FunctionDetail.tsx" /* webpackChunkName: "component---shared-templates-function-function-detail-tsx" */),
  "component---shared-templates-home-page-pux-home-page-tsx": () => import("./../../../../Shared/templates/HomePage/PuxHomePage.tsx" /* webpackChunkName: "component---shared-templates-home-page-pux-home-page-tsx" */),
  "component---shared-templates-knowledge-base-knowledge-base-article-tsx": () => import("./../../../../Shared/templates/KnowledgeBase/KnowledgeBaseArticle.tsx" /* webpackChunkName: "component---shared-templates-knowledge-base-knowledge-base-article-tsx" */),
  "component---shared-templates-knowledge-base-knowledge-base-category-tsx": () => import("./../../../../Shared/templates/KnowledgeBase/KnowledgeBaseCategory.tsx" /* webpackChunkName: "component---shared-templates-knowledge-base-knowledge-base-category-tsx" */),
  "component---shared-templates-knowledge-base-knowledge-base-tsx": () => import("./../../../../Shared/templates/KnowledgeBase/KnowledgeBase.tsx" /* webpackChunkName: "component---shared-templates-knowledge-base-knowledge-base-tsx" */),
  "component---shared-templates-landing-landing-page-tsx": () => import("./../../../../Shared/templates/Landing/LandingPage.tsx" /* webpackChunkName: "component---shared-templates-landing-landing-page-tsx" */),
  "component---shared-templates-news-news-detail-tsx": () => import("./../../../../Shared/templates/News/NewsDetail.tsx" /* webpackChunkName: "component---shared-templates-news-news-detail-tsx" */),
  "component---shared-templates-pricing-pricing-page-tsx": () => import("./../../../../Shared/templates/Pricing/PricingPage.tsx" /* webpackChunkName: "component---shared-templates-pricing-pricing-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-edit-tsx": () => import("./../../../src/pages/edit.tsx" /* webpackChunkName: "component---src-pages-edit-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */)
}

